















import { Component, Vue } from 'vue-property-decorator'

import ProgressList from '@/components/shared/ProgressList.vue'
import WelcomeComponent from '@/components/welcome/WelcomeComponent.vue'

import { getModule } from 'vuex-module-decorators'
import Configuration from '@/store/modules/configuration'
import Transcripts from '@/store/modules/transcripts'

const configuration = getModule(Configuration)
const transcripts = getModule(Transcripts)

@Component({
  components: {
    ProgressList,
    WelcomeComponent
  }
})
export default class WelcomeView extends Vue {
  async mounted () {
    configuration.load()

    transcripts.loadTranscriptions()
  }
}

