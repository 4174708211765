import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

const TIME_OUT = 35000

export default class AxiosApi {
  private static instance: AxiosApi

  private services: Record<string, AxiosInstance>

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor () {
    this.services = {}
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance (): AxiosApi {
    if (!AxiosApi.instance) {
      AxiosApi.instance = new AxiosApi()
    }

    return AxiosApi.instance
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateInstance (services: Array<any>): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    services.forEach((service: any) => {
      this.services[service.name] = axios.create({
        baseURL: service.api_url,
        timeout: TIME_OUT,
        headers: {
          'X-API-KEY': service.api_key
        }
      })
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public post (serviceName: string, ...args: [string, any | AxiosRequestConfig]): any {
    args.push({
      headers: {
      }
    })
    return this.services[serviceName].post.apply(this, args)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public put (serviceName: string, ...args: [string, any | AxiosRequestConfig]): any {
    args.push({
      headers: {
        'Content-Type': 'audio/wav'
      }
    })
    return this.services[serviceName].put.apply(this, args)
  }
}
