



























import Component, { mixins } from 'vue-class-component'
import { Vue } from 'vue-property-decorator'
import Translations from '@/store/modules/translations'
import { getModule } from 'vuex-module-decorators'
const translations = getModule(Translations) // Store accessor.

// Keys for accessing the translation json file.
const WELCOME_KEY = 'progressListWelcome'
const DETAILS_KEY = 'progressListYourDetails'
const AUDIO_CHECK_KEY = 'progressListBackgroundAudioCheck'
const RECORDINGS_KEY = 'progressListRecordings'
const DONE_KEY = 'progressListDone'

// Define the props by using Vue's canonical way.
const ProgressListProps = Vue.extend({
  props: {
    index: Number
  }
})

@Component
export default class ProgressList extends mixins(ProgressListProps, Vue) {
  itemSelected = 'list-inline-item ml-4 font-size-large selected font-weight-bold'
  itemNotSelected ='list-inline-item ml-4 font-size-large'

  // Initialise reactive data which stores text shown on UI.
  // (populated on mount)
  welcomeText: string | undefined = ''
  detailsText: string | undefined = ''
  backgroundCheckText: string | undefined = ''
  recordingsText: string | undefined = ''
  doneText: string | undefined = ''

  async mounted () {
    // Update the component data with the text from the translation store.
    // (By calling the getTranslations action from the translataions store)
    this.welcomeText = translations.getTranslations(WELCOME_KEY)
    this.detailsText = translations.getTranslations(DETAILS_KEY)
    this.backgroundCheckText = translations.getTranslations(AUDIO_CHECK_KEY)
    this.recordingsText = translations.getTranslations(RECORDINGS_KEY)
    this.doneText = translations.getTranslations(DONE_KEY)
  }
}
