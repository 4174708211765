















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { getModule } from 'vuex-module-decorators'
import User from '@/store/modules/user'
import Translations from '@/store/modules/translations'

import Store from '@/store/index'

import tcsCheckboxes from '@cfg/tcsCheckboxes.json'

type TcsCheckboxType = {
  text: string;
  value: string;
  required?: boolean;
}

const translations = getModule(Translations) // Store accessor.
const user = getModule(User, Store)

// Keys for accessing the translation json file.
const CLOSE_KEY = 'homeCloseText'
const CONTINUE_KEY = 'homeContinueText'
const TERMS_AND_CONDITIONS_KEY = 'homeTermsAndConditions'
const REQ_CHECKBOXES_KEY = 'homeRequiredCheckboxes'

@Component
export default class TermsAndConditionsModal extends Vue {
    @Prop({ default: false }) readonly show!: boolean

    closeText = translations.getTranslations(CLOSE_KEY)
    continueText = translations.getTranslations(CONTINUE_KEY)
    tcsTitleText = translations.getTranslations(TERMS_AND_CONDITIONS_KEY)
    reqCheckboxesText = translations.getTranslations(REQ_CHECKBOXES_KEY)
    checkboxes = tcsCheckboxes
    selectedCheckboxes: string[] = []
    canContinue = false

    @Watch('selectedCheckboxes')
    onAgreedTCSchanged (selectedCheckboxes: string[]) {
      const requiredCheckboxesValues = this.checkboxes.reduce((acc: string[], el) => {
        return el.required ? [...acc, el.value] : acc
      }, [])

      const selectedRequiredCheckboxes = requiredCheckboxesValues.filter((value) => {
        return selectedCheckboxes.includes(value)
      })

      const accepted = requiredCheckboxesValues.length === selectedRequiredCheckboxes.length
      this.canContinue = accepted
      user.updateAgreedTCs(accepted)
    }
}
