/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Store for the Translations (i.e the text that is seen on the UI, which is different depending on the region).
 * This store acts as the single source of truth for the text on the UI.
 */

import { Module, VuexModule } from 'vuex-module-decorators'
import Store from '../index'
import LANGUAGE_DATA from '@cfg/translations.json'

// Interface for the state definintion for this store.
export interface TranslationState {
  translatedText: Record<string, string | []>;
}
@Module({
  dynamic: true,
  store: Store,
  name: 'translations',
  namespaced: true
})

export default class Translations extends VuexModule implements TranslationState {
  // Define the Text object.
  // Type: Record<string, any> to enable the record to have any string key with values of any type (because some values are strings and others are arrays)
  translatedText: Record<string, any> = LANGUAGE_DATA

  // This functions takes in a key.
  // The function returns the translation for the key it has been given.
  // Tranlsations come from this.translatedText which is populated by IT.json.
  get getTranslations () {
    return (key: string) => { // Getters cannot have parameters in vue 2, therefore we return a function.
      /* eslint-disable dot-notation */
      const currentTranslation = this.translatedText[key] // Dynamically access the property of translatedText that we need using the key created above.
      if (currentTranslation !== undefined) {
        return currentTranslation // Return the current translation.
      } else {
        // The key did not return a translation.
        throw new Error('translation.ts:: No translation found for the given key.')
      }
    }
  }
}
