/* eslint-disable @typescript-eslint/camelcase */
/**
 * Store for the Recordings.
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import { RECORDING_STATE } from '@/constants'

import { v4 as uuidv4 } from 'uuid'

export interface RecordingState {
  quietTimeout: boolean;
  status: number;
  recordingId: string;
  audioContext: AudioContext|null;
}

@Module({
  name: 'recording',
  namespaced: true
})
export default class Recording extends VuexModule implements RecordingState {
  // recordings state defined below
  quietTimeout = false
  status = RECORDING_STATE.IDLE
  recordingId = ''
  audioContext: AudioContext|null = null

  // ***************
  // GETTERS
  // ***************
  get getQuietTimeout (): boolean {
    return this.quietTimeout
  }

  get getStatus (): number {
    return this.status
  }

  get getRecordingId (): string {
    return this.recordingId
  }

  get getAudioContext (): AudioContext|null {
    return this.audioContext
  }

  // ***************
  // MUTATIONS
  // ***************
  @Mutation
  public setQuietTimeout (value: boolean): void {
    this.quietTimeout = value
  }

  @Mutation
  public setRecordingId (value: string): void {
    this.recordingId = value
  }

  @Mutation
  public setAudioContext (value: AudioContext): void {
    this.audioContext = value
  }

  @Mutation
  public resetAll (): void {
    this.quietTimeout = false
    this.status = RECORDING_STATE.IDLE
    this.recordingId = ''
    this.audioContext = null
  }

  // ***************
  // ACTIONS
  // ***************
  @Mutation
  public updateStatus (value: number) {
    this.status = value
  }

  @Action({ commit: 'setQuietTimeout' })
  public async updateQuietTimeout (value: boolean) {
    return value
  }

  @Action({ rawError: true, commit: 'setRecordingId' })
  public async updateRecordingId (value: string | undefined = undefined) {
    // if no value provided, a uuid will be generated
    return value || uuidv4()
  }

  @Action({ commit: 'setAudioContext' })
  public async updateAudioContext (value: AudioContext) {
    return value
  }

  @Action({ commit: 'resetAll' })
  public async reset () {
    console.log('Reset Recording Store')
  }
}
