import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Store from '../index'

import { TRANSCRIPTS_AGE } from '@/constants'

@Module({
  dynamic: true,
  store: Store,
  name: 'transcripts',
  namespaced: true
})
export default class Transcripts extends VuexModule {
  public transcriptions: Record<string, Array<Record<string, string>>> = {}
  private transcriptionType: string = TRANSCRIPTS_AGE.DEFAULT

  get getTranscription () {
    return (index: number) => {
      return this.transcriptions[this.transcriptionType][index]
    }
  }

  get getTotalTranscriptType () {
    return (age: string, type: string) => {
      let totalTranscription = 0

      if (Object.keys(this.transcriptions).includes(age)) {
        totalTranscription = this.transcriptions[age].reduce((accumulator, transcript) => accumulator + (transcript.type === type ? 1 : 0), 0)
      }

      return totalTranscription
    }
  }

  get getTranscriptionsCount (): number {
    let totalTranscription = 0

    if (this.transcriptions && Object.keys(this.transcriptions).includes(this.transcriptionType)) {
      totalTranscription = this.transcriptions[this.transcriptionType].length
    }

    return totalTranscription
  }

  @Mutation
  public setTranscriptions (data: Record<string, Array<Record<string, string>>>): void {
    this.transcriptions = data
  }

  @Mutation
  public setTranscriptionType (type: string): void {
    this.transcriptionType = type
  }

  @Action({ commit: 'setTranscriptions' })
  public async loadTranscriptions (): Promise<void> {
    try {
      console.log('loadTranscriptions')

      const response = await fetch('/config/transcripts.json')
      const data = await response.json()

      return data
    } catch (error) {
      console.error(error)
    }
  }

  @Action({ commit: 'setTranscriptionType' })
  public async updateTranscriptionType (value: string) {
    return value
  }
}
