/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/**
 * Store for the Current Part.
 */
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

export interface RecordingPartState {
  index: number;
  type: string;
  transcript: string;
  fileName: string;
  recordingBlob: Blob;
  recording: any[];
}

 @Module({
   name: 'recordingPart',
   namespaced: true
 })
export default class RecordingPart extends VuexModule implements RecordingPartState {
  index = 0
  type = ''
  transcript = 'Hello Sky'
  fileName = ''
  recordingBlob = new Blob([''], { type: 'audio/wav' })
  recording = []

  // ***************
  // GETTERS
  // ***************
  get getIndex (): number {
    return this.index
  }

  get getType (): string {
    return this.type
  }

  get getTranscript (): string {
    return this.transcript
  }

  get getFileName (): string {
    return this.fileName
  }

  get getRecording (): any[] {
    return this.recording
  }

  get getRecordingBlob (): Blob {
    return this.recordingBlob
  }

   // ***************
   // MUTATIONS
   // ***************
   // Note: mutation below is for testing purposes only - don't use in implementation
   @Mutation
  public testSetIndex (): void {
    this.index = -1
  }

  @Mutation
   public incrementIndex (): void {
     this.index++
   }

  @Mutation
  public setType (value: string): void {
    this.type = value
  }

  @Mutation
  public setTranscript (value: string): void {
    console.log('setTranscript', value)

    this.transcript = value
  }

  @Mutation
  public setFileName (value: string): void {
    console.log('setFileName', value)

    this.fileName = value
  }

  @Mutation
  public appendData (value: []): void {
    console.log('appendData', value)

    this.recording = this.recording.concat(value)
  }

  @Mutation
  public setRecordingBlob (value: Blob): void {
    console.log('setRecordingBlob', value)

    this.recordingBlob = value
  }

  @Mutation
  public resetAll (): void {
    this.index = 0
    this.type = ''
    this.transcript = ''
    this.fileName = ''
    this.recording = []
  }

  @Mutation
  public resetRecordingData (): void {
    this.recording = []
  }

  // ***************
  // ACTIONS
  // ***************
  @Action({ commit: 'incrementIndex' })
  public async updateIndex () {
    console.log('updateIndex')
  }

  @Action({ commit: 'setType' })
  public async updateType (value: string) {
    return value
  }

  @Action({ commit: 'setTranscript' })
  public async updateTranscript (value: string) {
    return value
  }

  @Action({ commit: 'setFileName' })
  public async updateFileName (data: any) {
    return `${data.userId}_${data.recordingId}_${this.index}`
  }

  @Action({ commit: 'resetAll' })
  public async reset () {
    console.log('resetAll')
  }

  @Action({ commit: 'resetRecordingData' })
  public resetRecording () {
    console.log('resetRecording')
  }

  @Action({ commit: 'appendData' })
  public async updateRecording (value: []) {
    console.log('updateRecording', value)
    return value
  }

  @Action({ commit: 'setRecordingBlob' })
  public async updateRecordingBlob (value: Blob) {
    console.log('updateRecordingBlob')
    return value
  }
}
