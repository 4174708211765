/**
 * Store for the User.
 */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

// TODO - Move interface to its own file and implement it in this class.
export interface UserState {
   accessToken: string | null;
   userId: string | null;
   ageGroup: string | null;
   isValid: boolean;
   hasAgreedTCs: boolean;
 }
 @Module({
   name: 'user',
   namespaced: true
 })
export default class User extends VuexModule {
   public userId = '';
   public ageGroup = '';
   public isValid = false;
   public hasAgreedTCs = false;

   get getUserId (): string {
     return this.userId
   }

   get getAgeGroup (): string {
     return this.ageGroup
   }

   get getIsValid (): boolean {
     return this.isValid
   }

   get getHasAgreedTCs (): boolean {
     return this.hasAgreedTCs
   }

   // ***************
   // MUTATIONS
   // ***************

   @Mutation
   public setUserId (userId: string): void {
     this.userId = userId
   }

   @Mutation
   public resetAll (): void {
     this.userId = ''
     this.ageGroup = ''
     this.isValid = false
     this.hasAgreedTCs = false
   }

   @Mutation
   public resetAfterRecording (): void {
     this.userId = ''
     this.ageGroup = ''
     this.isValid = false
     this.hasAgreedTCs = false
   }

   @Mutation
   public updateIsValid (value: boolean): void {
     this.isValid = value
   }

   @Mutation
   public updateAgeGroup (value: string): void {
     this.ageGroup = value
   }

   // ***************
   // ACTIONS
   // ***************

   @Mutation
   public updateAgreedTCs (agreement: boolean) {
     this.hasAgreedTCs = agreement
   }

   @Action({ commit: 'setUserId' })
   public async updateUserId (userId: string) {
     return userId
   }

   @Mutation
   public reset () {
     this.userId = ''
     this.ageGroup = ''
     this.isValid = false
     this.hasAgreedTCs = false
   }
}
