



































































import TermsAndConditionsModal from './TermsAndConditionsModal.vue'
import BrowserUnsupportedModal from './BrowserUnsupportedModal.vue'

import { Component, Vue, Watch } from 'vue-property-decorator'

import Store from '../../store/index'

import { getModule } from 'vuex-module-decorators'
import User from '../../store/modules/user'
import Translations from '../../store/modules/translations'

// create tyepsafe accessor using getModule to access state of the User and Configuration from the store.
const user = getModule(User, Store)
const translations = getModule(Translations)

// Keys for accessing the translation json file.
const HELLO_KEY = 'homeHelloText'
const HELP_KEY = 'homeNeedHelpText'
const THANK_KEY = 'homeThankYouText'
const SAMPLES_KEY = 'homeSamplesText'
const SPARE_TIME_KEY = 'homeSpareTimeText'
const DATA_COLLECT_KEY = 'homeDataCollectionText'
const TERMS_KEY = 'homePleaseAcceptText'
const NEXT_KEY = 'homeNextText'

@Component({
  components: {
    TermsAndConditionsModal,
    BrowserUnsupportedModal
  }
})
export default class WelcomeComponent extends Vue {
    nextButtonDisabledClass = 'w-100 mt-2 mb-2 font-size-large nextButtonDisabled'
    nextButtonEnabledClass = 'w-100 mt-2 mb-2 font-size-large nextButtonEnabled'
    nextButtonStateClass = 'w-100 mt-2 mb-2 font-size-large nextButtonDisabled'

    showTermsAndConditionsModal = false

    isBrowserSupported = true // Default browser being supported to true, check is done on mount

    // Define local data to store the translated text.
    helloText = translations.getTranslations(HELLO_KEY)
    helpText = translations.getTranslations(HELP_KEY)
    thanksText = translations.getTranslations(THANK_KEY)
    samplesText = translations.getTranslations(SAMPLES_KEY)
    spareTimeText = translations.getTranslations(SPARE_TIME_KEY)
    dataText = translations.getTranslations(DATA_COLLECT_KEY)
    termsText = translations.getTranslations(TERMS_KEY)
    nextText = translations.getTranslations(NEXT_KEY)

    get userAgreedTCs () {
      return user.getHasAgreedTCs
    }

    @Watch('userAgreedTCs')
    // eslint-disable-next-line
    onUserAgreedTCsChanged (val: any, _: any) {
      this.nextButtonStateClass = val ? this.nextButtonEnabledClass : this.nextButtonDisabledClass
    }

    mounted () {
      try {
        this.isBrowserSupported = this.$browserDetect.isChrome || this.$browserDetect.isSafari || this.$browserDetect.isEdge || this.$browserDetect.isFirefox
        if (!this.isBrowserSupported) {
          this.$root.$emit('bv::show::modal', 'modal-browser-unsupported')
        }
      } catch (e) {
        console.log(e)
      }
    }

    routeToDetailsPage () {
      this.$router.push('/user-details')
    }
}
