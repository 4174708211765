import Vue from 'vue'
import VuexPersistence from 'vuex-persist'
import Vuex from 'vuex'

import User from '@/store/modules/user'
import Recording from '@/store/modules/recording'
import RecordingPart from '@/store/modules/recordingPart'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  // manage which items are persisted
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reducer: (state: any) => ({ user: state.user, recording: state.recording, recordingPart: state.recordingPart })
})

export default new Vuex.Store({
  state: {},
  modules: {
    user: User,
    recording: Recording,
    recordingPart: RecordingPart
  },
  plugins: [vuexLocal.plugin]
})
